import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"
import marked from "marked"
import PageHeadingTitle from "@components/pageHeadingTitle"

class BrandTemplate extends React.Component {
  render() {
    const { brand, applicationList } = this.props.data

    return (
      <Layout>
        <SEO
          title={brand.seo?.seoTitle || brand.subtitle}
          description={brand.seo?.seoDescription || brand.excerpt}
        />
        <div className="page-headline pb-0">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                <PageHeadingTitle
                  h1={"Information Center"}
                  h2={brand.subtitle}
                />
                {/* <span className="small">Posted on {post.publishedAt}</span> */}
                <div className="header-application text-center">
                  <p className="font-weight-bold text-primary h4">
                    Browse by Application
                  </p>
                  <ul className="list-inline application mb-0">
                    <li className="list-inline-item my-2">
                      <Link to="/products">
                        <span className="px-3 py-2 ">All</span>
                      </Link>
                    </li>
                    {applicationList.nodes.map(app => (
                      <li key={app.id} className="list-inline-item my-2">
                        <Link to={`/products/${app.slug.current}`}>
                          {" "}
                          <span className="px-3 py-2 ">{app.title}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-body">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-9">
                {brand.mainImage !== null ? (
                  <div className="text-center">
                    {/* <h2>{brand.title}</h2> */}
                    <img
                      src={brand.mainImage.asset.fluid.src}
                      alt="Blog Image"
                      className="img-fluid mb-4"
                    />
                  </div>
                ) : null}

                <div
                  dangerouslySetInnerHTML={{
                    __html: marked(brand.body || ""),
                  }}
                />

                <div className="row">
                  {brand.products.map(product => (
                    <div className="col-md-4 mb-5">
                      <div className="blog-item bg-light rounded border">
                        <Link to={`/${product.slug.current}`}>
                          <div className="blog-img-tile">
                            <div className="tile-bg-wrapper">
                              <span className="doc-icon">
                                <i className="fa fa-file-text"></i>
                              </span>
                              <div
                                className="tile-bg rounded-top"
                                style={{
                                  backgroundImage: `url(${product.mainImage &&
                                    product.mainImage.asset &&
                                    product.mainImage.asset.fluid &&
                                    product.mainImage.asset.fluid.src})`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </Link>
                        <h2 className="my-4 px-3 h4">
                          <Link to={`/${product.slug.current}`} className="h4">
                            {product.title}
                          </Link>
                        </h2>
                        <Link
                          to={`/${product.slug.current}`}
                          className="btn btn-secondary btn-secondary-red btn-sm m-3"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  ))}

                  {brand.subProduct.map(_subproduct => (
                    <div className="col-md-4 mb-5">
                      <div className="blog-item bg-light rounded border">
                        <Link to={`/${_subproduct.slug.current}`}>
                          <div className="blog-img-tile">
                            <div className="tile-bg-wrapper">
                              <span className="doc-icon">
                                <i className="fa fa-file-text"></i>
                              </span>
                              <div
                                className="tile-bg rounded-top"
                                style={{
                                  backgroundImage: `url(${_subproduct.mainImage &&
                                    _subproduct.mainImage.asset &&
                                    _subproduct.mainImage.asset.fluid &&
                                    _subproduct.mainImage.asset.fluid.src})`,
                                }}
                              ></div>
                            </div>
                          </div>
                        </Link>
                        <h2 className="my-4 px-3 h4">
                          <Link
                            to={`/${_subproduct.slug.current}`}
                            className="h4"
                          >
                            {_subproduct.title}
                          </Link>
                        </h2>
                        <Link
                          to={`/${_subproduct.slug.current}`}
                          className="btn btn-secondary btn-secondary-red btn-sm m-3"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BrandTemplate

export const brandQuery = graphql`
  query BrandTemplateQuery($id: String!) {
    brand: sanityBrand(id: { eq: $id }) {
      id
      excerpt
      title
      subtitle
      seo {
        seoTitle
        seoDescription
      }
      mainImage {
        asset {
          fluid {
            src
          }
        }
      }
      body
      products {
        id
        title
        slug {
          current
        }
        mainImage {
          asset {
            fluid {
              src
            }
          }
        }
      }
      subProduct {
        id
        title
        slug {
          current
        }
        mainImage {
          asset {
            fluid {
              src
            }
          }
        }
      }
    }
    applicationList: allSanityApplication {
      nodes {
        id
        title
        excerpt
        slug {
          current
        }
      }
    }
  }
`
